/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*-----------------------------------------------------------------------------------------------------------*/
/* Modal */
.modalCtrl-90w-90vh {
  --width: 90%;
  --max-width: 9vw;
  --height: 90vh;
}

.modalCtrl-ClienteDetalle {
  --width: 600px;
  --max-width: 95vw;
  --height: 85vh;
}

.modalCtrl-ClienteDetallePlanta {
  --width: 600px;
  --max-width: 95vw;
  --height: 380px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-ProyectoDetalle {
  --width: 600px;
  --max-width: 95vw;
  --height: 85vh;
}

.modalCtrl-InstruccionDetalle {
  --width: 800px;
  --max-width: 95vw;
  --height: 85vh;
}

.modalCtrl-InstruccionProcedimiento {
  --width: 900px;
  --max-width: 95vw;
  --height: 85vh;
  --backdrop-opacity: 0.8;
}

.modalCtrl-TecnicaEvaluacionDetallePage {
  --width: 600px;
  --max-width: 95vw;
  --height: 370px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-MetodoControlDetallePage {
  --width: 600px;
  --max-width: 95vw;
  --height: 370px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-MetodoControlDetallePage2 {
  --width: 600px;
  --max-width: 95vw;
  --height: 450px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-PiezaDetalle {
  --width: 600px;
  --max-width: 95vw;
  --height: 500px;
}

.modalCtrl-PiezaDetalle2 {
  --width: 600px;
  --max-width: 95vw;
  --height: 380px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-EquipoSeguridadDetalle {
  --width: 700px;
  --max-width: 95vw;
  --height: 400px;
}

.modalCtrl-InspeccionSemana {
  --width: 800px;
  --max-width: 95vw;
  --height: 85vh;
}

.modalCtrl-InspeccionDiaTurno {
  --width: 800px;
  --max-width: 95vw;
  --height: 85vh;
  --backdrop-opacity: 0.8;
}

.modalCtrl-InspeccionDiaTurnoDetalle {
  --width: 700px;
  --max-width: 95vw;
  --height: 85vh;
  --backdrop-opacity: 0.8;
}

.modalCtrl-DefectoDetalle2 {
  --width: 600px;
  --max-width: 95vw;
  --height: 380px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-CampoDinamicoDetalle2 {
  --width: 600px;
  --max-width: 95vw;
  --height: 380px;
  --backdrop-opacity: 0.8;
}

.modalCtrl-UsuarioDetalle {
  --width: 700px;
  --max-width: 95vw;
  --height: 85vh;
}

.modalCtrl-UsuarioDetalle2 {
  --width: 700px;
  --max-width: 95vw;
  --height: 85vh;
  --backdrop-opacity: 0.8;
}

/*-----------------------------------------------------------------------------------------------------------*/
.btnLabel {
  font-size: 0.7em;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Page header */
.pageHeader1 {
  background-color: #e15983;
  color: white;
  padding-left: 7px;
  height: 40px;
}

.pageHeader2 {
  background-color: #dddddd;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: bottom;
  flex-direction: column;
  padding-left: 15px;
}

.pageHeader3 {
  background-color: #6084b6;
  height: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-content: bottom;
  flex-direction: column;
}

.pageHeaderSeccion {
  /*background-color: #bbb;*/
  background-color: rgba(30, 53, 94, 0.4) !important;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: bottom;
  flex-direction: column;
  padding-left: 15px;
}

.pageHeaderSeccionRed {
  /*background-color: #bbb;*/
  background-color: rgba(172, 31, 45, 0.4) !important;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: bottom;
  flex-direction: column;
  padding-left: 15px;
}

.gridHeader {
  background-color: #eeeeee;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 0;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Sobreado de rows */
.itemRowOdd {
  background-color: white !important;
  --background: white !important;
}

.itemRowEven {
  background-color: #dad8d8 !important;
  --background: #dad8d8 !important;
}

.itemRowPositivo {
  background-color: rgba(0, 128, 0, 0.2) !important;
  --background: rgba(0, 128, 0, 0.2) !important;
}

.itemRowNegativo {
  background-color: rgba(255, 0, 0, 0.2) !important;
  --background: rgba(255, 0, 0, 0.2) !important;
}

.itemRowTotal {
  background-color: rgba(223, 185, 98, 0.3) !important;
  --background: rgba(223, 185, 98, 0.3) !important;
}

.itemRowNoEditable {
  background-color: #f9d2dd !important;
  --background: #f9d2dd !important;
}

.itemRowSelected {
  background-color: #f9d2dd !important;
  --background: #f9d2dd !important;
}

.itemRowSelected2 {
  background-color: #fbe4ea !important;
  --background: #fbe4ea !important;
}

.itemRowDisabled {
  background-color: #eeeeee !important;
  --background: #8f8f8f !important;
  color: #616161;
}

ion-item.menuItem:hover {
  --background: #f5f5f5;
  --background-hover-opacity: 0;
  cursor: pointer;
}

ion-item.gridItem:hover {
  --background: #f5f5f5;
  --background-hover-opacity: 0;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Datepicker Modal */
.li-ionic4-datePicker {
  .modal-wrapper {
    height: 475px;
    max-height: 475px;
    width: 350px;
    max-width: 350px;
  }
  --backdrop-opacity: 0.8;
}

.dp-days-list {
  color: #36485c;
}

.dp-weeks-name {
  color: #36485c;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* TimePicker Modal */
.owl-dialog-container {
  height: 158px !important;
}

.owl-dt-control {
  background-color: #f9d2dd !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  height: 46px;
}

.owl-dt-container-buttons {
  color: white !important;
}

.owl-dt-container-row {
  border: none;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* cols */
.showFiltros {
  display: none;
}

.showItemSmall {
  display: none;
}

.showItemFull {
  display: unset;
}

.col-container {
  display: table; /* Make the container element behave like a table */
  width: 100%; /* Set full-width to expand the whole page */
}

.colLeft {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 30%;
}

.colCenter {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 40%;
}

.colRight {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 30%;
}

.col10 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 10%;
}

.col25 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 25%;
}

.col33 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 33%;
}

.col40 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 40%;
}

.col50 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 50%;
}

.col60 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 60%;
}

.col70 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 70%;
}

.col80 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 80%;
}

.col90 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 90%;
}

.col100 {
  display: table-cell; /* Make elements inside the container behave like table cells */
  width: 100%;
}

.div80vh {
  background-color: white;
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Grid Item */

// * Item Requerido
.requerido {
  border: 1px red solid;
  background-color: rgba(255, 0, 0, 0.1) !important;
  --background: rgba(255, 0, 0, 0.1) !important;
}

// * Item Separador
.separador {
  border: 1px rgb(204, 204, 204) solid;
  background-color: rgba(204, 204, 204, 0.1) !important;
  --background: rgba(255, 0, 0, 0.1) !important;
}

.positivo {
  background-color: rgba(0, 128, 0, 0.1) !important;
  --background: rgba(0, 128, 0, 0.1) !important;
}

.negativo {
  background-color: rgba(255, 0, 0, 0.1) !important;
  --background: rgba(255, 0, 0, 0.1) !important;
}

// Texto del item categoria
.categoriaTxt {
  padding-left: 5px;
  vertical-align: text-bottom;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Select / Combo */

// text-wrap en los select-options
.sc-ion-label-md-h {
  white-space: normal !important;
}

// text-wrap en los select-text
ion-select::part(text) {
  white-space: normal !important;
}

.ion-select-noWrap::part(text) {
  white-space: nowrap !important;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Alert */

// alert ancho minimo (incluey alerts de los select)
.sc-ion-alert-md-h {
  --max-width: 450px;
  --min-width: 450px;
}

// .popover-content.sc-ion-popover-md {
//   --min-width: 450px;
//   width: 450px;
//   min-width: 450px;
// }

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
  max-height: 350px !important;
}

// text-wrap en los select-options
.alert-radio-label.sc-ion-alert-md {
  white-space: normal !important;
}

// color de los textos de los botones de los alert
.alert-button-inner.sc-ion-alert-md {
  color: white !important;
}

// color de los textos de los botones de los alert, que surjen por selects
.select-alert .alert-button-inner.sc-ion-alert-md {
  color: #36485c !important;
}

.alertContinuar {
  background-color: green !important;
}

.alertSalir {
  background-color: red !important;
}

.popover-content {
  --min-width: 350px;
}

.loading {
  --spinner-color: #36485c;
}

.toastBottom2 {
  position: fixed !important;
  bottom: 60px !important;
  --ion-safe-area-bottom: 50px !important;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Controles / Forma */
ion-select {
  color: #36485c;
}

ion-input {
  color: #36485c;
  --color: #36485c;
}

ion-label {
  color: #36485c !important;
}

ion-button {
  color: #dddddd;
}

span {
  color: #36485c;
}

input {
  color: #36485c;
}

ion-col {
  color: #36485c;
}

.graph {
  height: 470px !important;
}

.graph2 {
  height: 650px !important;
}

@media only screen and (max-width: 600px) {
  .showFiltros {
    display: unset;
  }

  .showItemSmall {
    display: unset;
  }

  .showItemFull {
    display: none;
  }

  .colLeft,
  .colCenter,
  .colRight,
  .col10,
  .col25,
  .col33,
  .col40,
  .col50,
  .col60,
  .col70,
  .col80,
  .col90,
  .col100 {
    display: block;
    width: 100%;
  }

  .div80vh {
    background-color: white;
    height: unset;
    max-height: unset;
    overflow-y: unset;
  }

  .popover-content {
    --min-width: 300px;
    --width: 300px;
    --max-width: 300px;
  }

  .sc-ion-alert-md-h {
    --min-width: 300px;
    --width: 300px;
    --max-width: 300px;
  }

  .graph {
    height: 330px !important;
  }

  .graph2 {
    height: 450px !important;
  }
}

@media only screen and (max-width: 400px) {
  
  .sc-ion-alert-md-h {
    --max-width: 95%;
    --min-width: 95%;
  }

  .popover-content.sc-ion-popover-md {
    --min-width: 95%;
    width: 95%;
    min-width: 95%;
  }
}

// Hover Imagen
.imgHover:hover {
  background-color: #3F3F95;
}

/*-----------------------------------------------------------------------------------------------------------*/
/* Ion-Viewer */
ion-toolbar {
  background: #1E355E !important;
  --background: #1E355E !important;
  color: white !important;
}

ion-footer ion-toolbar {
  background: white !important;
  --background: white !important;
  color: white !important;
}

/* Bug popover in chrome 114 */
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}