// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  /** primary  **/
  --ion-color-primary: #1E355E;
	--ion-color-primary-rgb: 30,53,94;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1E355E;
	--ion-color-primary-tint: #1E355E;

  /** secondary **/
  --ion-color-secondary: #AC1F2D;
	--ion-color-secondary-rgb: 172,31,45;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #afac8f;
	--ion-color-secondary-tint: #cdc9ab;

  /** tertiary **/
  --ion-color-tertiary: #DA4D51;
	--ion-color-tertiary-rgb: 218,77,81;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #c04447;
	--ion-color-tertiary-tint: #de5f62;

  /** success **/
  --ion-color-success: #4BB543;
	--ion-color-success-rgb: 75,181,67;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #429f3b;
	--ion-color-success-tint: #5dbc56;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}



/** white **/
:root {
  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0,0,0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}


/** Rosa Obscuro **/
:root {
  --ion-color-darkPink: #E15983;
  --ion-color-darkPink-rgb: 225,89,131;
  --ion-color-darkPink-contrast: #183C6E;
  --ion-color-darkPink-contrast-rgb: 0,0,0;
  --ion-color-darkPink-shade: #c64e73;
  --ion-color-darkPink-tint: #e46a8f;
}

.ion-color-darkPink {
  --ion-color-base: var(--ion-color-darkPink);
  --ion-color-base-rgb: var(--ion-color-darkPink-rgb);
  --ion-color-contrast: var(--ion-color-darkPink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkPink-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkPink-shade);
  --ion-color-tint: var(--ion-color-darkPink-tint);
}


/** blue **/
:root {

  --ion-color-blue: #36485c;
  --ion-color-blue-rgb: 54,72,92;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #303f51;
  --ion-color-blue-tint: #4a5a6c;

}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}


/* orange */
:root {
  --ion-color-orange: #FF4500;
  --ion-color-orange-rgb: 255,69,0;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255,255,255;
  --ion-color-orange-shade: #e03d00;
  --ion-color-orange-tint: #ff581a;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

/* light gray */
:root {
  --ion-color-lightgray: #DDDDDD;
  --ion-color-lightgray-rgb: 221,221,221;
  --ion-color-lightgray-contrast: #000000;
  --ion-color-lightgray-contrast-rgb: 0,0,0;
  --ion-color-lightgray-shade: #c2c2c2;
  --ion-color-lightgray-tint: #e0e0e0;
}

.ion-color-lightgray {
  --ion-color-base: var(--ion-color-lightgray);
  --ion-color-base-rgb: var(--ion-color-lightgray-rgb);
  --ion-color-contrast: var(--ion-color-lightgray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgray-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgray-shade);
  --ion-color-tint: var(--ion-color-lightgray-tint);
}

/* green */
:root {
  --ion-color-green: #2e9e35;
  --ion-color-green-rgb: 46,158,53;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #288b2f;
  --ion-color-green-tint: #43a849;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

/* red */
:root {
  --ion-color-red: #B71C1C;
  --ion-color-red-rgb: 255,0,0;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #e00000;
  --ion-color-red-tint: #ff1a1a;
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

